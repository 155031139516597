"use client";

import type { SupportedLanguage } from "@/utils/i18n";
import { useMemo, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import { languages } from "@/utils/i18n";
import type { NavbarTheme } from "../../../../components/Nav/Nav";
import { Nav } from "../../../../components/Nav/Nav";
import { HeaderSlider } from "./HeaderSlider";

// const useBackgroundTheme = (): NavbarTheme => {
//   const [theme, setTheme] = useState<NavbarTheme>("green");

//   const detectTheme = useCallback(() => {
//     const findParentWithBackground = () => {
//       const themeColors = {
//         beige: "bg-beige-3",
//         green: "bg-green",
//         blue: "bg-purple",
//       };

//       let currentElement = document.querySelector("header");
//       if (!currentElement) return null;

//       while (currentElement.parentElement) {
//         currentElement = currentElement.parentElement;
//         const backgroundColor = window.getComputedStyle(currentElement).backgroundColor;
//         const normalizedColor = backgroundColor.replace(/\s/g, "");

//         for (const [theme, color] of Object.entries(themeColors)) {
//           if (normalizedColor === color) {
//             return theme as NavbarTheme;
//           }
//         }
//       }

//       return null;
//     };

//     const detectedTheme = findParentWithBackground();
//     if (detectedTheme) {
//       setTheme(detectedTheme);
//     }
//   }, []);

//   useEffect(() => {
//     detectTheme();
//     const observer = new MutationObserver(detectTheme);

//     observer.observe(document.body, {
//       attributes: true,
//       attributeFilter: ["style", "class"],
//       subtree: true,
//     });

//     return () => observer.disconnect();
//   }, [detectTheme]);

//   return theme;
// };

export const Header = ({
  onChangeEnded,
  onChangeStarted,
  lang,
  theme
}: {
  lang: SupportedLanguage;
  onChangeEnded?: (key: string) => void;
  onChangeStarted?: (key: string) => void;
  theme: NavbarTheme;
}) => {
  const router = useRouter();
  const fullPathName = usePathname();
  const pathName = useMemo(() => {
    const [_empty, language, path] = (fullPathName || "/").split("/");
    if (language && languages.map(lang => lang.id as string).includes(language)) {
      return `/${path || ""}`;
    }
    return `/${language || ""}`;
  }, [fullPathName]);
  const [current, setCurrent] = useState(pathName);
  // const currentTheme = useBackgroundTheme();

  // const [themeStyle, setThemeStyle] = useState(themeStyles.beige);

  // useEffect(() => {
  //   const selectedTheme = currentTheme ?? "beige";
  //   if (selectedTheme in themeStyles) {
  //     setThemeStyle(themeStyles[selectedTheme as keyof typeof themeStyles]);
  //   } else {
  //     setThemeStyle(themeStyles.beige);
  //   }
  // }, [currentTheme]);

  return <>
      <Nav lang={lang} onChangeEnded={onChangeEnded} override={current} theme={theme} data-sentry-element="Nav" data-sentry-source-file="Header.tsx" />

      <div className="overflow-hidden bg-beige-3 pt-[90px] md:px-16 xl:pt-36">
        <HeaderSlider initialActiveKey={pathName || "/"} onChange={key => {
        if (key !== pathName) {
          router.push(key);
        }
        onChangeEnded?.(key);
      }} onChangeStarted={key => {
        onChangeStarted?.(key);
        setCurrent(key);
      }} data-sentry-element="HeaderSlider" data-sentry-source-file="Header.tsx" />
      </div>
    </>;
};