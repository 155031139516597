"use client";

import type { VariantProps } from "class-variance-authority";
import { cn } from "@/utils/tw";
import { cva } from "class-variance-authority";
import type { Icons } from "./Icon";
import { Icon } from "./Icon";
import { Tooltip } from "./Tooltip";
const chipVariants = cva("flex max-w-full items-center gap-2 border border-transparent px-3 py-1 text-sm", {
  variants: {
    variant: {
      default: "bg-background",
      primary: "bg-black text-white",
      "primary-light": "bg-primary-light text-primary-dark",
      secondary: "bg-secondary",
      "secondary-light": "bg-secondary-light text-primary-dark",
      white: "bg-white",
      outline: "border-black text-primary-text",
      "gray-outline": "border-stroke-gray text-inactive-gray",
      gray: "bg-inactive-gray text-white",
      purple: "bg-purple text-beige-3"
    },
    compact: {
      true: "max-w-[160px] truncate",
      false: ""
    },
    rounded: {
      true: "rounded-full",
      false: "rounded-md"
    }
  },
  defaultVariants: {
    variant: "default",
    compact: false,
    rounded: false
  }
});
export interface ChipProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof chipVariants> {
  icon?: keyof typeof Icons;
  className?: string;
  rounded?: boolean;
  children: React.ReactNode;
  onDelete?: () => void;
}
export const Chip = (props: ChipProps) => {
  return <Tooltip content={props.children} disabled={!props.compact} data-sentry-element="Tooltip" data-sentry-component="Chip" data-sentry-source-file="Chip.tsx">
      <div onClick={props.onClick} className={cn(chipVariants({
      variant: props.variant,
      compact: props.compact,
      rounded: props.rounded
    }), props.onClick ? "cursor-pointer" : "", props.className)}>
        {props.icon ? <Icon icon={props.icon} size="small" variant="ghost" /> : null}
        <div className={cn("flex-1", props.compact ? "truncate" : "")}>{props.children}</div>
        {props.onDelete ? <Icon icon="close" size="small" variant="ghost" onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        props.onDelete?.();
      }} /> : null}
      </div>
    </Tooltip>;
};