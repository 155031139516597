"use client";

import type { ListingWithCompany } from "@/components/ListingCard/ListingCard";
import Link from "next/link";
import { ListingCard } from "@/components/ListingCard/ListingCard";
import { Button } from "@/components/ui/Button";
import { Text } from "@/components/ui/Text";
import { useLocaleProvider } from "../../LocaleProvider";
import { useIsClient } from "@/hooks/useIsClient";
export const FeaturedListings = ({
  listings
}: {
  listings: ListingWithCompany[];
}) => {
  const isClient = useIsClient();
  const {
    country
  } = useLocaleProvider();
  if (country !== "NO" && isClient) {
    return null;
  }
  return <div className="bg-beige-3 py-20" data-sentry-component="FeaturedListings" data-sentry-source-file="FeaturedListings.tsx">
      <div className="container relative flex flex-col gap-16">
        <Text.H3 className="max-w-lg text-purple" data-sentry-element="unknown" data-sentry-source-file="FeaturedListings.tsx">Explore open positions</Text.H3>
        <div className="grid grid-cols-1 flex-col gap-4 overflow-x-auto md:grid-cols-2 md:gap-6 lg:grid-cols-3">
          {listings.map(listing => <ListingCard key={listing.id} listing={listing} />)}
        </div>
        <div className="pointer-events-none absolute bottom-0 left-0 flex w-full items-center justify-center bg-gradient-to-t from-beige-3 via-beige-3 to-transparent py-16 pt-32">
          <Link href="/discovery" data-sentry-element="Link" data-sentry-source-file="FeaturedListings.tsx">
            <Button variant="yellow" rounded className="pointer-events-auto" icon="rightArrow" data-sentry-element="Button" data-sentry-source-file="FeaturedListings.tsx">
              See all listings
            </Button>
          </Link>
        </div>
      </div>
    </div>;
};