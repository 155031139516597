"use client";

import { cn } from "@/utils/tw";
import { Text } from "../ui/Text";
export const Logo = (props: {
  logoUrl?: string | null;
  name: string;
  className?: string;
  onClick?: () => void;
}) => {
  return <picture id="logo" className={cn("relative flex h-[48px] w-[48px] items-center justify-center", props.className)} onClick={props.onClick} data-sentry-element="picture" data-sentry-component="Logo" data-sentry-source-file="Logo.tsx">
      {props.logoUrl ?
    // eslint-disable-next-line @next/next/no-img-element
    <img src={props.logoUrl} alt={props.name} className="h-full w-full object-contain object-center"
    // TODO
    onError={({
      currentTarget
    }) => {
      currentTarget.onerror = null; // prevents looping
      // currentTarget.src = "//unsplash.it/200/200";
    }} /> : <Text.H5 className="truncate text-lg">{props.name}</Text.H5>}
    </picture>;
};