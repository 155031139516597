/**
 * helper function for sharing / copying a message
 * @param message message to share
 * @param url url to share
 * @returns is shared true, if copied false
 */
export const shareOrCopy = async (
  msg: { message: string; url?: string } | PromiseLike<{ message: string; url?: string }>,
  hideText?: boolean,
): Promise<boolean> => {
  if ("then" in msg && typeof msg.then === "function") {
    if (hideText) {
      return navigator.clipboard
        .write([
          new ClipboardItem({
            "text/plain": msg.then(
              (el) => new Blob([el.url || el.message], { type: "text/plain" }),
            ),
          }),
        ])
        .then(() => false);
    }
    const response = navigator.clipboard
      .write([
        new ClipboardItem({
          "text/plain": msg.then(
            (el) =>
              new Blob(
                [el.message.includes(el.url || "") ? el.message : el.message + "\n" + el.url],
                { type: "text/plain" },
              ),
          ),
        }),
      ])
      .then(() => false);
    if (navigator.share) {
      const { message, url } = await msg;
      return navigator
        .share({
          text: message,
          url: url ? `${url} ` : undefined,
        })
        .then(() => true);
    }
    return response;
  }
  if (!("then" in msg)) {
    if (hideText) {
      return navigator.clipboard.writeText(msg.url || msg.message).then(() => false);
    }
    const { message, url } = msg;
    const response = navigator.clipboard
      .writeText(message.includes(url || "") ? message : message + "\n" + url)
      .then(() => false);
    if (navigator.share) {
      return navigator
        .share({
          text: message,
          url: url ? `${url} ` : undefined,
        })
        .then(() => true);
    }
    return response;
  }
  throw new Error("Unable to share");
};
