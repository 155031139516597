import { capitalize } from "radash";

export const schemeUrl = (url: string) => {
  if (url.startsWith("https://")) {
    return url;
  } else if (url.startsWith("http://")) {
    return url.replace("http://", "https://");
  }
  return `https://${url}`;
};

/* 
  this regex will pass for the following url examples
  test.com
  test.org/
  http://test.com
  http://test.com/
  https://test.com/test/?test=te
*/

export const urlRegex =
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const hostnameRegex = /^(?!https?:\/\/)([a-zA-Z0-9.-]+)\.[a-zA-Z]{2,}$/; // ? url, but explicitly without protocol

export const urlRegexWithProtocol = new RegExp(
  "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$", // fragment locator
  "i",
);

export const isUrl = (url: string) => {
  return !!urlRegex.test(url);
};

export const whiteSpaceRegex = /\s/g;

export const extractSchemeAndRest = (url: string) => {
  const regex = /^([a-zA-Z0-9-]+):\/\/(.*)$/;
  const match = url.match(regex);
  return match?.[2] ? { scheme: match[1], rest: match[2] } : { scheme: null, rest: url };
};

export const getDomainWithoutSubdomainOrPath = (rest: string) => {
  // split by / to get domain without path, then by . to get domain parts
  const domainParts = rest.split("/")[0]?.split(".");

  // if the domain structure is present, return the main domain without subdomains
  if (domainParts && domainParts.length >= 2) {
    return domainParts[domainParts.length - 2]! + "." + domainParts[domainParts.length - 1]!;
  }

  // if there's no domain structure, return the rest
  return rest;
};

interface DomainMapping {
  partial: string;
  mappedUrl: string;
}

const DOMAIN_MAPPINGS: DomainMapping[] = [
  { partial: "matcha", mappedUrl: "Direct" },
  { partial: "vercel", mappedUrl: "Direct" },
  { partial: "lnkd", mappedUrl: "LinkedIn.com" },
  { partial: "linkedin", mappedUrl: "LinkedIn.com" },
  { partial: "x.com", mappedUrl: "X.com" },
  { partial: "twitter", mappedUrl: "X.com" },
  { partial: "twttr", mappedUrl: "X.com" },
  { partial: "t.co", mappedUrl: "X.com" },
  { partial: "youtube", mappedUrl: "Youtube.com" },
  { partial: "youtu", mappedUrl: "Youtube.com" },
  { partial: "reddit", mappedUrl: "Reddit.com" },
  { partial: "redd.it", mappedUrl: "Reddit.com" },
  { partial: "facebook", mappedUrl: "Facebook.com" },
  { partial: "fb.com", mappedUrl: "Facebook.com" },
  { partial: "fb.me", mappedUrl: "Facebook.com" },
  { partial: "instagram", mappedUrl: "Instagram.com" },
  { partial: "instagr.am", mappedUrl: "Instagram.com" },
  { partial: "ig.me", mappedUrl: "Instagram.com" },
  { partial: "tiktok", mappedUrl: "TikTok.com" },
  { partial: "snapchat", mappedUrl: "Snapchat.com" },
  { partial: "google", mappedUrl: "Google.com" },
  { partial: "goo.gl", mappedUrl: "Google.com" },
  { partial: "g.page", mappedUrl: "Google.com" },
  { partial: "g.co", mappedUrl: "Google.com" },
  // Add more mappings as required
];

export const normalizeDomain = (domain: string) =>
  DOMAIN_MAPPINGS.find((mapping) => domain.toLowerCase().includes(mapping.partial))?.mappedUrl ||
  capitalize(domain);
