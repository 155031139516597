"use client";

import { useState } from "react";
import Image from "next/image";
import { Icon } from "@/components/ui/Icon";
import { Text } from "@/components/ui/Text";
export const FAQ = ({
  questions
}: {
  questions: {
    question: React.ReactNode;
    answer: React.ReactNode;
  }[];
}) => {
  const [isOpen, setIsOpen] = useState<number | undefined>();
  return <div className="overflow-hidden bg-green py-20 md:overflow-visible md:pb-64" data-sentry-component="FAQ" data-sentry-source-file="FAQ.tsx">
      <div className="container relative flex gap-8 max-md:flex-col max-md:gap-32">
        <Text.H1 className="flex-1 text-green-3" data-sentry-element="unknown" data-sentry-source-file="FAQ.tsx">What people ask</Text.H1>
        <Image src="/img/landing-page/landing.svg" alt="" width={559} height={845} className="pointer-events-none absolute left-8 top-8 max-md:-top-24 max-md:left-40 max-md:scale-125" data-sentry-element="Image" data-sentry-source-file="FAQ.tsx" />
        <div className="z-10 w-full max-w-lg">
          {questions.map((el, i) => <div key={i} className="group flex items-center justify-between gap-2 rounded-2xl px-4 py-6 text-green-3 transition-all hover:cursor-pointer hover:bg-green-3 hover:text-green md:px-6" onClick={() => setIsOpen(isOpen === i ? undefined : i)}>
              <div className="space-y-2">
                <Text.P strong>{el.question}</Text.P>
                {isOpen === i ? <Text.P>{el.answer}</Text.P> : null}
              </div>
              <Icon icon={isOpen === i ? "upChevron" : "downChevron"} className="text-green opacity-0 group-hover:opacity-100" />
            </div>)}
        </div>
      </div>
    </div>;
};