"use client";

import { useEffect, useState } from "react";
import Link from "next/link";
import { Animation } from "@/components/ui/Animation";
import { Text } from "@/components/ui/Text";
import { cn } from "@/utils/tw";
export const VoucherAnimation = ({
  shouldAnimate
}: {
  shouldAnimate: boolean;
}) => {
  const [shouldJump, setShouldJump] = useState(false);
  const [hasJumped, setHasJumped] = useState(false);
  // observe if user has scrolled at all
  useEffect(() => {
    void fetch("/img/diver-jumping.json");
    const handleScroll = () => {
      if (window.scrollY > 40 && shouldAnimate) {
        setShouldJump(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [shouldAnimate]);
  return <div className="absolute bottom-2 right-8 z-0 max-w-xs lg:bottom-20 lg:right-32 lg:mx-0 lg:h-[600px] lg:w-full lg:max-w-[600px]" data-sentry-component="VoucherAnimation" data-sentry-source-file="VoucherAnimation.tsx">
      <Animation url={shouldJump ? "/img/diver-jumping.json" : "/img/diver.json"} onMouseOver={() => {
      if (shouldAnimate) {
        setShouldJump(true);
      }
    }} loop={!shouldJump} className={cn("h-full w-full", {
      hidden: hasJumped
    })} onComplete={() => {
      if (shouldJump) {
        setHasJumped(true);
      }
    }} data-sentry-element="Animation" data-sentry-source-file="VoucherAnimation.tsx" />
      <Animation url={"/img/diver-shimmer.json"} className={cn("h-full w-full", {
      hidden: !hasJumped
    })} data-sentry-element="Animation" data-sentry-source-file="VoucherAnimation.tsx" />
      <Link href="/discovery" className={cn({
      hidden: !hasJumped
    })} data-sentry-element="Link" data-sentry-source-file="VoucherAnimation.tsx">
        <div className="group absolute bottom-12 right-10 flex h-1/6 w-1/4 items-center justify-center lg:bottom-16 lg:right-36 lg:h-28 lg:w-28">
          <Text.P className="font-handwriting opacity-0 group-hover:opacity-100" data-sentry-element="unknown" data-sentry-source-file="VoucherAnimation.tsx">
            View Job Board
          </Text.P>
        </div>
      </Link>
      <Text.P big className="absolute -left-4 w-20 font-handwriting max-lg:top-32 max-md:hidden lg:-left-8 lg:bottom-48 lg:w-48" data-sentry-element="unknown" data-sentry-source-file="VoucherAnimation.tsx">
        Einar made $6000 vouching for Vegard!
      </Text.P>
    </div>;
};