"use client";

import type { MotionValue } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Text } from "@/components/ui/Text";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { cn } from "@/utils/tw";
import { motion, useScroll, useTransform } from "framer-motion";
type Item = {
  title: string;
  step: string;
  description: string;
  additional: React.ReactNode;
};
export const Scroller = ({
  items = []
}: {
  items: Item[];
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const {
    scrollYProgress
  } = useScroll({
    target: ref,
    offset: ["start start", "90% end"]
  });
  const isHorizontalScroll = useMediaQuery("(min-width: 1024px)");
  const x = useTransform(scrollYProgress, e => {
    if (!ref.current || !innerRef.current || !isHorizontalScroll) return 0;
    return -e * (((innerRef.current.firstChild as HTMLDivElement).clientWidth + 16 * 4) * items.length - 16 * 4 - innerRef.current.offsetWidth + Number(window.getComputedStyle(innerRef.current).getPropertyValue("padding-left").replace("px", "")) * 2);
  });
  return <div className="relative bg-beige-3 contain-paint lg:pt-[50vh]" data-sentry-component="Scroller" data-sentry-source-file="Scroller.tsx">
      <div className="relative" ref={ref} style={{
      minHeight: isHorizontalScroll ? `${items.length * 100}vh` : "none"
    }}>
        <motion.div className="container flex items-start gap-16 py-28 will-change-transform max-lg:sticky max-lg:flex-col lg:sticky lg:top-1/2 lg:py-0" style={{
        x
        // y,
      }}
      // transformTemplate={({ x, y }) => `translate3d(${x}, ${y}, 0px)`}
      ref={innerRef} data-sentry-element="unknown" data-sentry-source-file="Scroller.tsx">
          {items.map((el, i) => <ScrollerItem key={i} item={el} breakpointSmall={(i - 1) / (items.length - 1)} breakpointBig={i / (items.length - 1)} breakpointSmallAgain={(i + 1) / (items.length - 1)} progress={scrollYProgress} />)}
        </motion.div>
      </div>
    </div>;
};
const ScrollerItem = ({
  item,
  breakpointSmall,
  breakpointBig,
  breakpointSmallAgain,
  progress
}: {
  item: Item;
  breakpointSmall: number;
  breakpointBig: number;
  breakpointSmallAgain: number;
  progress: MotionValue<number>;
}) => {
  const range = [breakpointSmall, breakpointBig - (breakpointBig - breakpointSmall) / 2, breakpointBig + (breakpointSmallAgain - breakpointBig) / 2, breakpointSmallAgain];
  const opacity = useTransform(progress, range, [0, 1, 1, 0], {
    ease: easeInOutQuint
  });
  const opacityForAdditional = useTransform(progress, [breakpointSmall, breakpointBig - (breakpointBig - breakpointSmall) * 1 / 12, breakpointBig + (breakpointSmallAgain - breakpointBig) * 1 / 12, breakpointSmallAgain], [0, 1, 1, 0], {
    ease: easeInOutQuint
  });
  const textOpacity = useTransform(progress, range, [0.6, 1, 1, 0.6], {
    ease: easeInOutQuint
  });
  const height = useTransform(progress, range, [1.6, 1, 1, 1.6], {
    ease: easeInOutQuint
  });
  const [aspectRatio, setAspectRatio] = useState(height.get());
  const [fontSize, setFontSize] = useState(opacity.get() * 30 + 40);
  useEffect(() => {
    height.on("change", setAspectRatio);
  }, [height]);
  useEffect(() => {
    opacity.on("change", el => setFontSize(el * 20 + 48));
  }, [opacity]);
  const isHorizontalScroll = useMediaQuery("(min-width: 1024px)");
  return <motion.div className={cn("squircle-large relative flex w-full max-w-xl flex-shrink-0 flex-col justify-center gap-4 bg-beige-2 p-8 transition-all max-md:h-[70vh] md:p-16 lg:-translate-y-1/2")} transition={{
    duration: 1
  }} style={{
    aspectRatio: aspectRatio
  }} data-sentry-element="unknown" data-sentry-component="ScrollerItem" data-sentry-source-file="Scroller.tsx">
      <motion.div className="squircle-large absolute left-0 top-0 h-full w-full bg-yellow" style={{
      opacity: opacity
    }} data-sentry-element="unknown" data-sentry-source-file="Scroller.tsx" />
      <div className="relative h-full w-full overflow-hidden text-purple">
        <motion.div className="absolute z-10 flex h-full flex-col gap-6 md:gap-2 lg:justify-between" style={{
        opacity: textOpacity
      }} data-sentry-element="unknown" data-sentry-source-file="Scroller.tsx">
          <div>
            <Text.P small className="mb-4 opacity-60" data-sentry-element="unknown" data-sentry-source-file="Scroller.tsx">
              {item.step}
            </Text.P>
            <Text.H3 style={{
            fontSize: isHorizontalScroll ? fontSize : undefined,
            lineHeight: 1
          }} data-sentry-element="unknown" data-sentry-source-file="Scroller.tsx">
              {item.title}
            </Text.H3>
          </div>
          <motion.div style={{
          opacity: opacity
        }} data-sentry-element="unknown" data-sentry-source-file="Scroller.tsx">
            <Text.P className="max-w-40 font-semibold opacity-60" data-sentry-element="unknown" data-sentry-source-file="Scroller.tsx">{item.description}</Text.P>
          </motion.div>
        </motion.div>
      </div>
      <motion.div style={{
      opacity: opacityForAdditional
    }} data-sentry-element="unknown" data-sentry-source-file="Scroller.tsx">{item.additional}</motion.div>
    </motion.div>;
};
function easeInOutQuint(x: number): number {
  return x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2;
}