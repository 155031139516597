"use client";

import type { PageContent } from "@/utils/i18n";
import type { Company, GoogleMapsLocation, Listing } from "@prisma/client";
import Image from "next/image";
import Link from "next/link";
import { useLocaleProvider } from "@/app/[lang]/LocaleProvider";
import { env } from "@/env.mjs";
import { relativeFromNow } from "@/utils/dayjsUtils";
import { tExperience } from "@/utils/i18n";
import { shareOrCopy } from "@/utils/shareOrCopy";
import { renderReward } from "@/utils/string";
import { trpc } from "@/utils/TrpcProvider";
import { cn } from "@/utils/tw";
import { Logo } from "../Logo/Logo";
import { Button } from "../ui/Button";
import { Chip } from "../ui/Chip";
import { Text } from "../ui/Text";
import { useToast } from "../ui/use-toast";
const t = {
  reward: {
    en: "Reward",
    no: "i belønning",
    dk: "i belønning"
  },
  expires: {
    en: "Expires",
    no: "Utløper",
    dk: "Udløber"
  },
  noExpirationDate: {
    en: "No expiration date",
    no: "Ingen utløpsdato",
    dk: "Ingen udløbsdato"
  },
  vouch: {
    en: "Vouch now",
    no: "Vouch nå",
    dk: "Vouch nu"
  },
  copied: {
    en: "Copied to clipboard",
    no: "Kopiert til utklippstavle",
    dk: "Kopieret til udklipsholder"
  }
} satisfies PageContent;
export type ListingWithCompany = Pick<Listing, "title" | "id" | "currency" | "bounty" | "publishedAt" | "updatedAt" | "slug" | "experience" | "expirationDate" | "createdAt" | "draft" | "activated" | "language" | "bannerUrl" | "bannerConfig" | "highlights"> & {
  company: Pick<Company, "id" | "website" | "logoUrl" | "name" | "industries" | "description" | "slug" | "brandColor" | "language" | "bannerUrl" | "bannerConfig">;
  locations?: Pick<GoogleMapsLocation, "city" | "country">[];
}; // RouterOutputs["listings"]["getAll"][0];

export function ListingCard(props: {
  listing: ListingWithCompany;
  isSelected?: boolean;
  variant?: string;
  hideActions?: boolean;
  onChipClicked?: ListingCardDetailsProps["onChipClicked"];
}) {
  const {
    lang
  } = useLocaleProvider();
  const {
    toast
  } = useToast();
  const getReferral = trpc.misc.findReferral.useMutation();
  return <div className={cn("squircle-large group relative z-0 block h-min w-full overflow-hidden bg-beige-2 transition-all md:h-auto")} data-sentry-component="ListingCard" data-sentry-source-file="ListingCard.tsx">
      <Link href={`/listings/${props.listing.slug}`} className="absolute inset-0 left-0 z-0 h-full w-full cursor-pointer select-none" data-sentry-element="Link" data-sentry-source-file="ListingCard.tsx" />
      <div className="z-0 flex h-full flex-col gap-2">
        <div className="pointer-events-none relative z-0 h-24 w-full">
          {props.listing.company.bannerUrl && <Image src={props.listing.company.bannerUrl} alt={props.listing.company.name} fill className="object-cover object-top" />}
        </div>
        <div className="absolute left-8 top-14 h-16 w-16 rounded-lg bg-white p-2 md:left-12">
          <Link href={`/company/${props.listing.company.slug}`} data-sentry-element="Link" data-sentry-source-file="ListingCard.tsx">
            <Logo name={props.listing.company.name || ""} className="z-10 mr-4 h-12 w-12 cursor-pointer" logoUrl={props.listing.company.logoUrl} data-sentry-element="Logo" data-sentry-source-file="ListingCard.tsx" />
          </Link>
        </div>
        <div className="absolute right-8 top-16 z-20 opacity-0 transition-all group-hover:opacity-100 max-md:opacity-100 md:right-12">
          <Button variant="ghost" icon="link" size="sm" onClick={async e => {
          e.preventDefault();
          e.stopPropagation();
          await shareOrCopy(getReferral.mutateAsync({
            type: "LISTING_SHARING",
            listingId: props.listing.id,
            companyId: props.listing.company.id
          }).then(data => ({
            message: `${env.NEXT_PUBLIC_APP_BASE_URL}/${lang}/listings/${props.listing.slug}?r=${data?.slug || ""}`
          })), true);
          toast({
            title: t.copied[lang]
          });
        }} data-sentry-element="Button" data-sentry-source-file="ListingCard.tsx" />
        </div>
        <div className="pointer-events-none z-0 select-text p-8 md:p-12 md:pt-8">
          <div className="w-full flex-1">
            <ListingCardDetails listing={props.listing} onChipClicked={props.onChipClicked} data-sentry-element="ListingCardDetails" data-sentry-source-file="ListingCard.tsx" />
          </div>
          <div>
            <div className="my-4 h-[1px] w-full bg-purple-7" />
            <div className="flex items-center">
              <div className="flex w-full items-center gap-2">
                <Text.P data-sentry-element="unknown" data-sentry-source-file="ListingCard.tsx">
                  {renderReward(lang, props.listing.currency, props.listing.bounty)}{" "}
                  {t.reward[lang]}
                </Text.P>
              </div>
            </div>
            <div className="flex items-center">
              {props.listing.expirationDate ? <Text.P small light muted className="truncate">
                  {t.expires[lang]} {relativeFromNow(lang, props.listing.expirationDate)}
                </Text.P> : <Text.P small light muted>
                  {t.noExpirationDate[lang]}
                </Text.P>}
            </div>
          </div>
        </div>
      </div>
    </div>;
}
export type ListingCardDetailsProps = {
  listing: ListingWithCompany;
  className?: string;
  hideTags?: boolean;
  onChipClicked?: (type: "tag", item: {
    id: string;
    value: string;
  }) => void;
};
export const ListingCardDetails = (props: ListingCardDetailsProps) => {
  const {
    lang
  } = useLocaleProvider();
  const tags = props.listing.highlights;
  return <div className={cn("relative flex flex-col rounded-lg", props.className)} data-sentry-component="ListingCardDetails" data-sentry-source-file="ListingCard.tsx">
      <div className="flex flex-col gap-2 pt-2">
        <Text.H5 data-sentry-element="unknown" data-sentry-source-file="ListingCard.tsx">
          {props.listing.title.endsWith(props.listing.company.name) ? props.listing.title.replace(props.listing.company.name, "") : props.listing.title}
        </Text.H5>
        <div className="flex items-center gap-4">
          <Text.P small data-sentry-element="unknown" data-sentry-source-file="ListingCard.tsx">{props.listing.company.name}</Text.P>
          <div className="flex items-center gap-2">
            <Text.P small muted data-sentry-element="unknown" data-sentry-source-file="ListingCard.tsx">
              {props.listing.locations?.map(l => `${l.city}`).join(" | ")}
            </Text.P>
            <div className="h-3 w-[1px] bg-purple-7" />
            <Text.P small muted data-sentry-element="unknown" data-sentry-source-file="ListingCard.tsx">
              Remote
            </Text.P>
          </div>
        </div>
      </div>
      {!props.hideTags && <div className="mt-2 flex w-full flex-wrap gap-2 overflow-hidden">
          {tags?.slice(0, 4)?.map(tag => <Chip rounded variant="purple" key={tag}>
              {tag}
            </Chip>)}
          {props.listing.experience.slice(0, 4)?.map(tag => <Chip rounded variant="purple" key={tag}>
              {tExperience[lang]?.[tag]}
            </Chip>)}
          {(props.listing.highlights || []).length > 4 && <Chip rounded variant="purple" key={"more"}>
              <Text.P className="truncate text-white" small>
                + {(props.listing.highlights || []).length - 4}
              </Text.P>
            </Chip>}
        </div>}
    </div>;
};