"use client";

import type { SwiperClass } from "swiper/react";
import { useEffect, useMemo, useRef, useState } from "react";
import Link from "next/link";
import { Animation } from "@/components/ui/Animation";
import { Button } from "@/components/ui/Button";
import { Text } from "@/components/ui/Text";
import { useIsClient } from "@/hooks/useIsClient";
import { cn } from "@/utils/tw";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDebounce } from "use-debounce";
import { CalComButton } from "../../(static)/business/CalComButton";
import { useLocaleProvider } from "../../LocaleProvider";
import { VoucherAnimation } from "./VoucherAnimation";
export const HeaderSlider = ({
  initialActiveKey,
  onChange,
  onChangeStarted
}: {
  initialActiveKey: string;
  onChange: (key: string) => void;
  onChangeStarted: (key: string) => void;
}) => {
  const isClient = useIsClient();
  const {
    country
  } = useLocaleProvider();
  const items = useMemo(() => {
    return [{
      id: "/",
      renderItem: <div className="container relative mx-0 w-dvw pb-8 text-purple md:px-2 lg:pb-32">
            <div className="squircle-large large bg-beige-2 p-8 pb-80 pt-12 lg:px-20 lg:pb-48 lg:pt-16">
              <VoucherAnimation shouldAnimate={initialActiveKey === "/"} />
              <div className="relative">
                <Text.H3 className="max-w-2xl">Help your network land dream jobs.</Text.H3>
                <Text.P className="mt-6 max-w-lg">
                  Vouch for someone for a new job and get rewarded if they are hired.
                </Text.P>
                {isClient && country === "NO" ? <Link href="/discovery" className="z-10 mt-16 inline-block">
                    <Button rounded variant="yellow">
                      View job board
                    </Button>
                  </Link> : <Link href="#newsletter" className="z-10 mt-16 inline-block">
                    <Button rounded variant="yellow">
                      Join the newsletter
                    </Button>
                  </Link>}
              </div>
            </div>
          </div>
    }, {
      id: "/companies",
      renderItem: <div className="container relative mx-0 w-dvw pb-8 text-purple md:px-2 lg:pb-32">
            <div className="squircle-large bg-beige-2 p-8 pb-96 pt-12 lg:px-20 lg:pb-48 lg:pt-16">
              <div className="absolute bottom-0 right-4 lg:bottom-12 lg:right-24">
                <Animation url={"/img/fisher.json"} className="w-[800px] max-lg:w-80 lg:mx-0 lg:h-[600px] lg:w-[450px]" />
                <Text.P big className="absolute bottom-48 left-48 w-20 font-handwriting max-md:hidden lg:-left-48 lg:bottom-28 lg:w-40">
                  Wilhelmsen hired the best Head of ESG through Vouch!
                </Text.P>
              </div>
              <div className="relative">
                <Text.H3 className="max-w-3xl">Catch passive talents via referrals.</Text.H3>
                <Text.P className="mt-6 max-w-md xl:max-w-2xl">
                  Create job ads in seconds, engage your networks, and reel in vouched-for talents
                  with none of the admin pains.
                </Text.P>
                <div className="mt-16 flex gap-2">
                  <Link href="/onboarding" className="block">
                    <Button rounded variant="yellow">
                      Get started in seconds
                    </Button>
                  </Link>
                  <CalComButton variant="ghost" bordered>
                    Book a demo
                  </CalComButton>
                </div>
              </div>
            </div>
          </div>
    }, {
      id: "/candidates",
      renderItem: <div className="container relative mx-0 w-dvw pb-8 text-purple md:px-2 lg:pb-32">
            <div className="squircle-large bg-beige-2 p-8 pb-80 pt-12 lg:px-20 lg:pb-48 lg:pt-16">
              <div className="absolute max-lg:-bottom-24 max-lg:right-[-250px] lg:-right-40 lg:-top-16">
                <Animation url={"/img/skydiver.json"} className="mx-auto w-[800px] lg:mx-0 lg:h-[857px] lg:w-[1143px]" />
                <Text.P big className="absolute w-56 font-handwriting max-lg:bottom-40 max-lg:right-[300px] max-md:hidden lg:bottom-72 lg:right-60">
                  Nadia landed her dream role through Vouch!
                </Text.P>
              </div>
              <div className="relative">
                <Text.H3 className="max-w-2xl">Land your dream job.</Text.H3>
                <Text.P className="mt-6 max-w-xl">
                  Get vouched for, when you apply for your next role.
                </Text.P>
                {isClient && country === "NO" ? <Link href="/discovery" className="z-10 mt-16 inline-block">
                    <Button rounded variant="yellow">
                      View job board
                    </Button>
                  </Link> : <Link href="#newsletter" className="z-10 mt-16 inline-block">
                    <Button rounded variant="yellow">
                      Join the newsletter
                    </Button>
                  </Link>}
              </div>
            </div>
          </div>
    }];
  }, [initialActiveKey, isClient, country]);
  const swiper = useRef<SwiperClass>();
  const [activeItem, setActiveItem] = useState(items.findIndex(item => item.id === initialActiveKey));
  const [debouncedActiveItem] = useDebounce(activeItem, 300);
  useEffect(() => {
    onChange(items[debouncedActiveItem]!.id);
  }, [debouncedActiveItem, onChange, items]);
  const [isLoaded, setIsLoaded] = useState(false);
  return <>
      <div className={cn("fixed left-0 top-0 z-[1000] h-dvh w-dvw bg-beige-3", {
      hidden: isLoaded
    })} />
      <Swiper onSwiper={ref => {
      swiper.current = ref;
    }} onInit={() => {
      setIsLoaded(true);
    }} className="mx-0 w-full overflow-visible p-0"
    // https://github.com/nolimits4web/swiper/issues/6942
    // mousewheel={{
    //   enabled: true,
    //   forceToAxis: true,
    //   sensitivity: 3,
    //   thresholdDelta: 30,
    // }}
    // modules={[Mousewheel]}
    slidesPerView={"auto"} spaceBetween={4} initialSlide={items.findIndex(item => item.id === initialActiveKey)} centeredSlides onSlideChange={({
      activeIndex
    }) => {
      if (activeIndex === activeItem) return;
      setActiveItem(activeIndex);
      onChangeStarted(items[activeIndex]!.id);
    }} data-sentry-element="Swiper" data-sentry-source-file="HeaderSlider.tsx">
        {items.map(item => <SwiperSlide className="relative w-auto cursor-grab active:cursor-grabbing max-lg:overflow-hidden" key={item.id}>
            {item.renderItem}
          </SwiperSlide>)}
      </Swiper>
    </>;
};